var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.CheckListFilterPanel,{attrs:{"dynamic-lists":_vm.dynamicLists,"is-filter-panel-visible":_setup.isFilterPanelVisible},on:{"update:filteredDynamicLists":_setup.updateFilteredDynamicLists,"cancelFilters":_setup.handleCancelFilters}}),_c(_setup.SdsTablePseudoScrollBar),_c('v-data-table',{staticClass:"shades transparent",attrs:{"headers":_setup.headers,"items":_setup.filteredDynamicLists,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.total,"loading":_vm.isLoading,"footer-props":_setup.footerProps,"no-data-text":"該当するデータがありません"},on:{"update:page":function($event){_setup.updatePage($event), _setup.fetchItems()},"update:items-per-page":function($event){_setup.updateItemsPerPage($event), _setup.fetchItems()}},scopedSlots:_vm._u([{key:`header.check`,fn:function(){return [_c('v-simple-checkbox',{attrs:{"indeterminate":_setup.isIndeterminate,"value":_setup.isAllChecked},on:{"input":_setup.handleClickAllCheck}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',{class:{
          'pointer-cursor': item.total !== 0,
          disabled: !item.is_query_ready,
        },on:{"click":function($event){item.total !== 0 ? _setup.navigate(item.id) : null}}},[_c('td',{style:({
            padding: '20px 16px !important',
          })},[(item.is_query_ready)?_c('v-simple-checkbox',{attrs:{"value":!!_vm.selectedDynamicLists.find((list) => list.id === item.id)},on:{"input":function($event){return _setup.handleCheckDynamicLists(item, $event)}}}):_vm._e()],1),_c('td',{style:({
            paddingTop: '20px !important',
            paddingBottom: '20px !important',
          })},[_c('div',{staticClass:"font-bold d-flex flex-row align-start"},[_c('div',{class:_setup.newJudge(item.created_at, item.updated_at, item.total)
                  ? 'new-label'
                  : 'fake-label'},[_vm._v(" NEW ")]),_c('div',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item.name)+" ")])])]),_c('td',{style:({
            paddingTop: '20px !important',
            paddingBottom: '20px !important',
            textAlign: 'center',
          })},[_vm._v(" "+_vm._s(item.total)+" ")]),_c('td',{staticClass:"description-text",style:({
            paddingTop: '20px !important',
            paddingBottom: '20px !important',
          })},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',{style:({
            paddingTop: '20px !important',
            paddingBottom: '20px !important',
          })},[_c('div',[_vm._v(_vm._s(_setup.formatToDate(item.updated_at)))]),_c('div',[(!item.is_query_ready)?_c('v-chip',{staticClass:"chip",attrs:{"outlined":"","small":""}},[_vm._v("作成中")]):(item.suspended_at !== null)?_c('v-chip',{staticClass:"chip",attrs:{"color":"error","small":""}},[_vm._v("更新中止")]):_c('v-chip',{staticClass:"chip",attrs:{"color":"primary","small":""}},[_vm._v("作成済み")])],1)]),_c('td',{style:({
            paddingTop: '20px !important',
            paddingBottom: '20px !important',
          })},[_vm._v(" "+_vm._s(_setup.formatToDate(item.created_at))+" ")]),_c('td',{style:({
            paddingTop: '20px !important',
            paddingBottom: '20px !important',
          })},[_vm._v(" "+_vm._s(_setup.getFullName(item.created_user, 'ケミカン運営'))+" ")])])]}},{key:"footer.page-text",fn:function(){return [_c('v-select',{attrs:{"label":"ページ選択","items":_setup.pageSelectItems,"value":_vm.page},on:{"input":_setup.updatePage}})]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }