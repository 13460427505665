export const getTagColor = (colorCode: number) => {
  switch (colorCode) {
    case 1:
      return '244,67,54';
    case 2:
      return '233,30,99';
    case 3:
      return '156,39,176';
    case 4:
      return '103,58,183';
    case 5:
      return '63,81,181';
    case 6:
      return '25,118,210';
    case 7:
      return '2,136,209';
    case 8:
      return '0,131,143';
    case 9:
      return '0,121,107';
    case 10:
      return '46,125,50';
    case 11:
      return '104,159,56';
    case 12:
      return '175,180,43';
    case 13:
      return '253,216,53';
    case 14:
      return '255,179,0';
    case 15:
      return '245,124,0';
    case 16:
      return '255,87,34';
    case 17:
      return '121,85,72';
    case 18:
      return '96,125,139';
    default:
      return '0,150,136';
  }
};
